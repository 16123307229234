import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _, { get } from "lodash";
import { useUserContext } from "../../UserContext";
import Swal from "sweetalert2";
import {
  getLead,
  UploadDcument,
  uploadOCRBack,
} from "../../services/apiService";
import LoadingOverlay from "../../components/Loading";

const VerifyInfoBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productInfo, setProductInfo } = useUserContext();
  const [listObjUrls, setListObjUrls] = useState([]);
  const [isImageCaptured,setIsImageCaptured] = useState(false);
  const lang = localStorage.getItem("lang");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({});
  const frontImageRef = useRef(null);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const backImageRef = useRef(null);
  const [kycSegment, setKycSegment] = useState(null);
  const finalId = id !== null ? id : userId;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadings(true);
        const leadResponse = await getLead(finalId);
        const { kyc_segment } = leadResponse.data.data;
        console.log("kycsegment trước khikhi format : ", kyc_segment);

        setKycSegment(kyc_segment);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadings(false);
      }
    };

    fetchData();
  }, [finalId]);

  function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const myCdnUrlPrefix = "https://vision-vnetwork-cdn.goevo.vn/";
  const customUrls = {
    blazefaceModelUrl: `${myCdnUrlPrefix}models/1.0.0/blazeface/model.json`,
    opencvUrl: `${myCdnUrlPrefix}opencv/4.6.0/cv.js`,
    idCardModelUrl: `${myCdnUrlPrefix}models/1.0.0/id_card/tflite/model-v3.tflite`,
    wechatQRModelsUrl: `${myCdnUrlPrefix}models/1.0.0/webchat_qr/`,
    tfScriptUrls: {
      tfUrl: `${myCdnUrlPrefix}tfjs/3.20.0/tf.min.js`,
      tfBackendWasmUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-wasm.js`,
      tfBackendCpuUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-cpu.js`,
      tfBackendWebGlUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-webgl.js`,
      tfLiteUrl: `${myCdnUrlPrefix}tflite/0.0.1-alpha.8/tflite.min.js`,
      blazefaceScriptUrl: `${myCdnUrlPrefix}blazeface/0.0.7/bf.js`,
    },
  };
  const sdkVersion = "5.22.11";
  useEffect(() => {
    const tv = new window.TVWebSDK.SDK({
      container: document.getElementById("web-sdk-capture-back"),
      lang: lang || "vi",
      enableAntiDebug: false,
      assetRoot: myCdnUrlPrefix + "tvweb-sdk/" + sdkVersion + "/assets",
      resourceRoot: myCdnUrlPrefix,
      customUrls: customUrls,
    });

    window.tv = tv;
    tv.runPreloadEKYCResources();

    const userIdEl = document.getElementById("user-id");
    userIdEl.value = JSON.parse(localStorage.getItem("user-id"));

    const selectModeEl = document.getElementById("select-mode");
    Object.keys(window.TVWebSDK.Constants.Mode).forEach((k) => {
      const option = document.createElement("option");
      option.appendChild(document.createTextNode(k));
      option.setAttribute("value", window.TVWebSDK.Constants.Mode[k]);
      selectModeEl.appendChild(option);
    });

    const idCardSettingsEl = document.getElementById("id-card-settings");
    const idCardStepsEl = document.getElementById("id-card-steps");
    const trackingConfigEl = document.getElementById("tracking-config");

    idCardSettingsEl.value = JSON.stringify(
      window.TVWebSDK.defaultClientSettings,
      null,
      2
    );
    idCardStepsEl.value = JSON.stringify(
      window.TVWebSDK.defaultReadIDCardSteps,
      null,
      2
    );
    trackingConfigEl.value = JSON.stringify(
      window.TVWebSDK.TrackingContext.defaultTrackingConfig,
      null,
      2
    );

    return () => {
      tv.destroyView();
    };
  }, [lang]);

  const handleSubmit = async (updatedBack) => {
    const newErrors = {};
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);

      try {
        const dataStatus = {
          OCR_Image_Back: updatedBack.OCR_Image_Back,
        };

        await UploadDcument(finalId, dataStatus);
        const ocrBackResponse = await uploadOCRBack(finalId);

        const status = String(
          get(ocrBackResponse, "data.data.status", "")
        ).toUpperCase();
        const cleanKycSegment = (() => {
          let segment = kycSegment;

          if (typeof segment !== "string") {
            if (segment == null) segment = "";
            else if (
              typeof segment === "number" ||
              typeof segment === "boolean"
            )
              segment = String(segment);
            else if (typeof segment === "object")
              segment = JSON.stringify(segment);
            else segment = "";
          }

          return (
            segment
              .normalize("NFKC")
              .replace(/[\t\r\n\f\v]/g, "")
              .replace(/[\u200B\u00A0]/g, "")
              .replace(/\s+/g, " ")
              .trim()
              .replace(/–|—/g, "-")
              .replace(/[^\w\s-]/gi, "")
              .replace(/-+$/, "")
              .toUpperCase() || "UNKNOWN"
          );
        })();

        console.log("kycsegment sau khi format : ", cleanKycSegment);
        switch (status) {
          case "SUCCESS":
            if (
              cleanKycSegment === "ETB_NON_VERIFIED" ||
              cleanKycSegment === "NTB"
            ) {
              console.log("➡️ Chuyển đến: /vpbank-creditcard/contact");
              navigate(`/vpbank-creditcard/contact${location.search}`);
            } else if (cleanKycSegment === "ETB_VERIFIED") {
              console.log("➡️ Chuyển đến: /vpbank-creditcard/face");
              navigate(`/vpbank-creditcard/face${location.search}`);
            }
            return;

          case "RETRY":
            Swal.fire({
              icon: "warning",
              title: "Yêu cầu chụp lại mặt trước!",
              text: get(ocrBackResponse, "data.data.message", ""),
              showCancelButton: true,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            navigate(`/vpbank-creditcard/ocr-front${location.search}`);
            return;

          case "RETRY_BACK":
            Swal.fire({
              icon: "warning",
              title: "Yêu cầu chụp lại mặt sau!",
              text: get(ocrBackResponse, "data.data.message", ""),
              showCancelButton: true,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            return;

          case "CANCEL":
            navigate(`/vpbank-creditcard/reject${location.search}`);
            return;

          case "CAPTURE_QR":
            Swal.fire({
              icon: "warning",
              title: get(
                ocrBackResponse,
                "data.data.message",
                "Vui lòng chụp ảnh QR!"
              ),
              showCancelButton: true,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            navigate(`/vpbank-creditcard/ocr-qr${location.search}`);
            return;

          default:
            console.warn("Trạng thái không xác định:", status);
            Swal.fire({
              icon: "error",
              title: "Lỗi không xác định",
              text: "Vui lòng thử lại sau.",
            });
            return;
        }
      } catch (error) {
        console.error("Cập nhật thất bại", error);
        Swal.fire({
          icon: "error",
          title: "Lỗi",
          text: "Đã xảy ra lỗi trong quá trình xử lý.",
        });
      } finally {
        setLoadings(false);
      }
    }
  };

  function createObjectURL(blob) {
    const objUrl = URL.createObjectURL(blob);
    setListObjUrls((prev) => [...prev, objUrl]);
    return objUrl;
  }

  function revokeObjectURL() {
    listObjUrls.forEach((item) => URL.revokeObjectURL(item));
    setListObjUrls([]);
  }

  function clearUserId() {
    window.localStorage.removeItem("user-id");
    const userIdEl = document.getElementById("user-id");
    userIdEl.value = "";
  }

  async function handleStepDoneIDCard({
    stepNumber,
    image,
    cardSide,
    cardType,
    qrScannedResult,
    recordedVideos,
    apiResult,
  }) {
    const base64Image = await convertBlobToBase64(image.blob);
    const imgObjUrl = createObjectURL(image.blob);
    if (cardSide === "front" && frontImageRef.current) {
      frontImageRef.current.src = imgObjUrl;
    } else if (cardSide === "back" && backImageRef.current) {
      backImageRef.current.src = imgObjUrl;
    }

    const resultExtractIdInfoEl = document.getElementById(
      "result-extract-id-info"
    );

    setIsImageCaptured(true);
    resultExtractIdInfoEl.appendChild(
      document.createTextNode("\n-----------------------------------------\n")
    );
    resultExtractIdInfoEl.appendChild(
      document.createTextNode(`\nSTEP NUMBER ${stepNumber}:\n`)
    );
    const updatedBack = {
      OCR_Image_Back:
      cardSide === "back"
        ? base64Image.replace(/^data:image\/[a-zA-Z]+;base64,/, "")
        : undefined,
    };
    setProductInfo((prevProductInfo) => ({
      ...prevProductInfo,
      ...updatedBack,
    }));

    const imgEl = document.createElement("img");
    imgEl.width = "300";
    imgEl.src = URL.createObjectURL(image.blob);
    resultExtractIdInfoEl.appendChild(imgEl);

    if (apiResult) {
      resultExtractIdInfoEl.appendChild(
        document.createTextNode("API Result:\n")
      );
      const apiResultEl = document.createElement("textarea");
      apiResultEl.value = JSON.stringify(apiResult, null, 2);
      apiResultEl.cols = 55;
      apiResultEl.rows = 15;
      resultExtractIdInfoEl.appendChild(apiResultEl);
    }
    window.tv.destroyView();
    handleSubmit(updatedBack);
  }
  async function handleDetectIdCardBack(props) {
    const { cardType, image } = props;
    return await handleDetectIdCardCommon({
      cardType,
      image,
      cardSide: "back",
    });
  }

  async function handleDetectIdCardCommon(props) {
    const { cardType, image, cardSide } = props;
    const apiClient = new window.trustvisionSdk.default(
      window.inputAccessKey.value,
      window.inputSecretKey.value,
      window.inputApiUrl.value
    );
    const resultUpload = await apiClient.uploadImage({
      file: image.blob,
      label: `id_card.${cardType}.${cardSide}`,
    });
    const imageId = resultUpload.data.image_id;
    const resultDetect = await apiClient.httpClient.post(
      "/v1/detect_id_cards_sync",
      {
        card_type: cardType,
        image: {
          id: imageId,
        },
      }
    );
    return _.get(resultDetect, "data.image.cards.0");
  }

  function startExtractIDInfoBack() {
    revokeObjectURL();
    const resultExtractIdInfoEl = document.getElementById(
      "result-extract-id-info"
    );
    resultExtractIdInfoEl.innerHTML = "";
    document.body.style.height = 0;

    const commonProps = {
      steps: [
        {
          cardSide: "back",
          cardType: "vn.national_id",
          description: "Vui lòng đặt CMND mặt sau vào trong khung",
          enableConfirmPopup: true,
          frontCamera: false,
          scannerType: "id_card",
          title: "Mặt sau CMND/CCCD",
          titleIcon: "id_card_back.svg",
        },
      ],
      onError: (e) => {
        resultExtractIdInfoEl.appendChild(
          document.createTextNode(`Error:\n${JSON.stringify(e, null, 2)}`)
        );
        window.tv.destroyView();
        document.body.style.height = "auto";
        document.getElementById("read-id-card-loading").style.display = "none";
      },
      onClose: () => {
        document.body.style.height = "auto";
        window.tv.destroyView();
      },
      detectIdCard: handleDetectIdCardBack,
      onStepDone: (stepResult) => {
        handleStepDoneIDCard(stepResult);
        window.tv.destroyView();
        document.body.style.height = "auto";
      },
      tracking: JSON.parse(document.getElementById("tracking-config").value),
    };

    if (window.inputAccessKey.value) {
      window.tv.readIDCardWithApiCall({
        apiCredentials: {
          accessKey: window.inputAccessKey.value,
          secretKey: window.inputSecretKey.value,
          apiUrl: window.inputApiUrl.value,
        },
        ...commonProps,
      });
    } else {
      window.tv.readIDCardUIOnly(commonProps);
    }
  }

  return (
    <div
      id="content"
      className="verify-info flex flex-col items-center p-5 bg-white  "
    >
      {" "}
      {loadings && <LoadingOverlay />}
      <div id="web-sdk-capture-back"></div>
      <h1 className="text-xl font-bold mt-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-yellow-600 to-yellow-500">
        Xác thực giấy tờ tuỳ thân
      </h1>
      <p className="text-xs font-normal mt-3 text-gray-600 text-center">
        Vui lòng chụp mặt sau CMND/CCCD
      </p>
      <div style={{ display: "none" }}>
        <input id="user-id" type="text" placeholder="User ID" />
        <button onClick={clearUserId}>Clear User ID</button>
      </div>
      <div style={{ display: "none" }}>
        <label htmlFor="select-mode">Select Mode:</label>
        <select id="select-mode"></select>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3"></div>
      <div
        id="read-id-card-loading"
        style={{ margintop: "10px", display: "none" }}
      >
        Api checking
        <div class="dot-windmill"></div>
      </div>
      <div className="h-[270px] w-[96%] bg-gray-300 rounded-3xl mt-5 flex flex-col items-center custom-shadow custom-shadow">
        <img
          id="backImage"
          ref={backImageRef}
          src={require("../../assets/ocrback.png")}
          alt="Chụp mặt sau"
          className="w-[84%] h-[210px] mt-4"
        />
        <p className="text-sm mt-2 font-semibold text-slate-700">
          Chụp mặt sau
        </p>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <pre id="result-extract-id-info2"></pre>
      </div>
      <div className="items-center justify-center mt-9 mb-14">
        <button
          onClick={startExtractIDInfoBack}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"
        >
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
      <div id="result-extract-id-info" style={{ display: "none" }}></div>
      <div id="result-liveness-detection"></div>
      <div id="id-card-settings"></div>
      <div id="id-card-steps"></div>
      <div id="tracking-config"></div>
      <div id="read-id-card-loading"></div>
      <div id="liveness-loading" style={{ display: "none" }}></div>
    </div>
  );
};

export default VerifyInfoBack;
